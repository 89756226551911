import React, { Suspense, useMemo } from 'react';

import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { SystemStyleObject } from '@mui/system';

import { styles } from './styles';
import { IconName } from './types';

export interface Props {
  name: IconName;
  width?: number | string;
  height?: number | string;
  color?: string;
  testId?: string;
  viewBox?: string;
  sx?: SxProps | SystemStyleObject<Theme>[];
  onClick?(event: React.MouseEvent<HTMLInputElement>): void;
}

const IconComponent: React.FC<Props> = ({
  name,
  width,
  height,
  color,
  testId,
  onClick,
  sx = {},
  ...rest
}) => {
  const SvgIcon = React.lazy(() => import(`assets/icons/${name}`));

  const StyledSvg = useMemo(
    () =>
      styled(SvgIcon)({
        minWidth: width,
        minHeight: height,
        fill: color,
        '& g': {
          fill: color,
        },
        ...(sx as React.CSSProperties),
      }),
    [SvgIcon, color, height, sx, width]
  );

  return (
    <Suspense fallback={<Box sx={styles.iconFallback} width={width} height={height} />}>
      <StyledSvg
        width={width}
        height={height}
        fill={color}
        data-testid={testId}
        className={name}
        onClick={onClick}
        {...rest}
      />
    </Suspense>
  );
};

const Icon = React.memo(IconComponent);

export { Icon };
