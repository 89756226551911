import { nanoid } from 'nanoid';
import qs from 'query-string';
import { matchPath, useLocation } from 'react-router-dom';
import { appRoutes } from 'routes';

const response_type = 'id_token token';
const scope = 'openid profile';

const getPathname = (pathname: string) => {
  const isCreateFlow = [
    appRoutes.createNativeImage,
    appRoutes.createSeamlessImage,
    appRoutes.createSeamlessVideo,
    appRoutes.createSeamlessCarousel,
    appRoutes.createAdoptimizeClicks,
    appRoutes.createDeals,
    appRoutes.createListingAd,
    appRoutes.createSeamlessImageCrossDevice,
    appRoutes.createSeamlessImageMini,
    appRoutes.createBrandedContent,
    appRoutes.createBrandedContentADR,
    appRoutes.createBrandedContentNuNl,
    appRoutes.createBrandedContentVTP,
    appRoutes.createBrandedContentLMF,
    appRoutes.createBrandedContentHLN,
    appRoutes.createBrandedContentDeMorgen,
    appRoutes.createBrandedContentVideo,
    appRoutes.createTweakersFeaturedProduct,
    appRoutes.createTweakersFeaturedShop,
    appRoutes.createTweakersAdvertorial,
  ].some((route) => Boolean(matchPath({ path: route }, pathname)));
  if (isCreateFlow) {
    return { pathname };
  }

  const isEditFlow = [
    appRoutes.nativeImage,
    appRoutes.seamlessImage,
    appRoutes.seamlessVideo,
    appRoutes.seamlessCarousel,
    appRoutes.adoptimizeClicks,
    appRoutes.deals,
    appRoutes.listingAd,
    appRoutes.seamlessImageCrossDevice,
    appRoutes.seamlessImageMini,
    appRoutes.brandedContent,
    appRoutes.brandedContentADR,
    appRoutes.brandedContentNuNl,
    appRoutes.brandedContentVTP,
    appRoutes.brandedContentLMF,
    appRoutes.brandedContentHLN,
    appRoutes.brandedContentDeMorgen,
    appRoutes.brandedContentVideo,
    appRoutes.tweakersFeaturedProduct,
    appRoutes.tweakersFeaturedShop,
    appRoutes.tweakersAdvertorial,
  ].some((route) => Boolean(matchPath({ path: route }, pathname)));
  if (isEditFlow) {
    const indexOfId = pathname.lastIndexOf('/');
    const url = `${pathname.slice(0, indexOfId)}/:id`;
    return { pathname: url, redirectUri: pathname };
  }
  return { pathname, redirectUri: pathname };
};

export const useOneLoginAuth = () => {
  const { pathname, search } = useLocation();

  const { pathname: url, redirectUri: customRedirectUri } = getPathname(pathname);
  const beginAuth = () => {
    const params = qs.stringify({
      client_id: process.env.REACT_APP_ONE_LOGIN_CLIENT_ID,
      redirect_uri: `${window.location.origin}${url !== '/' ? url : '/overview'}`,
      response_type,
      scope,
      state:
        JSON.stringify({
          customRedirectUri,
          search,
        }) ?? nanoid(32),
      nonce: nanoid(32),
      grant_type: 'client_credentials',
    });
    const authUrl = `${process.env.REACT_APP_ONE_LOGIN_AUTHORITY}/auth?${params}`;

    window.location.assign(authUrl);
  };
  return { beginAuth, customRedirectUri };
};
