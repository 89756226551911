import { AD_STATUS, AdFormat } from 'api';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';

import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

export const ROW_HEIGHT = 73;

export const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const getIsCreativeSet = (id: string) => uuidRegex.test(String(id));

export const useHandleEdit = () => {
  const navigate = useNavigate();
  const handleEdit = (type: AdFormat, id: string) => {
    switch (type) {
      case AdFormat.SEAMLESS_VIDEO:
      case AdFormat.SEAMLESS_VIDEO_L:
      case AdFormat.SEAMLESS_VIDEO_XL:
        navigate(appRoutes.seamlessVideo.replace(':id', id));
        break;
      case AdFormat.LEADGEN_SEAMLESS_VIDEO:
      case AdFormat.LEADGEN_SEAMLESS_VIDEO_L:
      case AdFormat.LEADGEN_SEAMLESS_VIDEO_XL:
        navigate(appRoutes.leadgenSeamlessVideo.replace(':id', id));
        break;
      case AdFormat.SEAMLESS_IMAGE:
      case AdFormat.SEAMLESS_IMAGE_L:
      case AdFormat.SEAMLESS_IMAGE_XL:
        navigate(appRoutes.seamlessImage.replace(':id', id));
        break;
      case AdFormat.LEADGEN_SEAMLESS_IMAGE:
      case AdFormat.LEADGEN_SEAMLESS_IMAGE_L:
      case AdFormat.LEADGEN_SEAMLESS_IMAGE_XL:
        navigate(appRoutes.leadgenSeamlessImage.replace(':id', id));
        break;
      case AdFormat.SEAMLESS_CAROUSEL:
        navigate(appRoutes.seamlessCarousel.replace(':id', id));
        break;
      case AdFormat.NATIVE:
        navigate(appRoutes.nativeImage.replace(':id', id));
        break;
      case AdFormat.ADOPTIMIZE_CLICKS:
        navigate(appRoutes.adoptimizeClicks.replace(':id', id));
        break;
      case AdFormat.DEALS:
        navigate(appRoutes.deals.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT:
        navigate(appRoutes.brandedContent.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_ADR:
        navigate(appRoutes.brandedContentADR.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_NU_NL:
        navigate(appRoutes.brandedContentNuNl.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_VTP:
        navigate(appRoutes.brandedContentVTP.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_LMF:
        navigate(appRoutes.brandedContentLMF.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_HLN:
        navigate(appRoutes.brandedContentHLN.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_DE_MORGEN:
        navigate(appRoutes.brandedContentDeMorgen.replace(':id', id));
        break;
      case AdFormat.LISTING_AD:
        navigate(appRoutes.listingAd.replace(':id', id));
        break;
      case AdFormat.SEAMLESS_IMAGE_CROSS_DEVICE:
        navigate(appRoutes.seamlessImageCrossDevice.replace(':id', id));
        break;
      case AdFormat.SEAMLESS_IMAGE_MINI:
        navigate(appRoutes.seamlessImageMini.replace(':id', id));
        break;
      case AdFormat.BRANDED_CONTENT_VIDEO:
        navigate(appRoutes.brandedContentVideo.replace(':id', id));
        break;
      case AdFormat.TWEAKERS_FEATURED_PRODUCT:
        navigate(appRoutes.tweakersFeaturedProduct.replace(':id', id));
        break;
      case AdFormat.TWEAKERS_FEATURED_SHOP:
        navigate(appRoutes.tweakersFeaturedShop.replace(':id', id));
        break;
      case AdFormat.TWEAKERS_ADVERTORIAL:
        navigate(appRoutes.tweakersAdvertorial.replace(':id', id));
        break;

      default:
        navigate(appRoutes.overview);
        break;
    }
  };
  return { handleEdit };
};

export const allowedFormatsForBulkUpload = [
  AdFormat.SEAMLESS_IMAGE,
  AdFormat.SEAMLESS_IMAGE_L,
  AdFormat.SEAMLESS_IMAGE_XL,
  AdFormat.NATIVE,
  AdFormat.SEAMLESS_CAROUSEL,
  AdFormat.SEAMLESS_VIDEO,
  AdFormat.SEAMLESS_VIDEO_L,
  AdFormat.SEAMLESS_VIDEO_XL,
];

export const pageSizeOptions = [10, 50, 100];

export const getRowClassName = (params: GridRowClassNameParams<any>): string => {
  const isCreativeSetChildClassName =
    params.row.hierarchy?.length > 1 ? 'CreativeSetRow' : '';
  const isLastCreativeSetChild = params.row.isLastCreativeSetChild ? 'last-child' : '';
  return `${isCreativeSetChildClassName} ${isLastCreativeSetChild}`;
};

export const getCreativesStates = (creativesList: { state: AD_STATUS }[]): boolean => {
  if (!creativesList?.length) {
    return false;
  }
  const uniqueStatusesOfCreativeList = [
    ...new Set(creativesList?.map((creative) => creative.state)),
  ];

  return uniqueStatusesOfCreativeList?.length > 1 ?? false;
};
