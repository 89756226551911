import { createAppRoutes } from 'utils/createAppRoutes';

export const API_ENDPOINTS = {
  campaignData: '/media-asset/campaign-data',
  mediaAssets: '/media-asset/creatives',
  creativeAssetWithId: '/creative/creatives/:id',
  creativeSetWithId: '/creative/creative-sets/:id',
  creativeByIds: '/creative/creatives',
};

export const endpoints = createAppRoutes(API_ENDPOINTS);
