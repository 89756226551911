import { appRoutes } from './appRoutes';

export const viewRoutes: { path: string; isCreate?: boolean; type?: 'BULK' }[] = [
  // edit routes
  {
    path: appRoutes.seamlessImage,
  },
  {
    path: appRoutes.seamlessVideo,
  },
  {
    path: appRoutes.leadgenSeamlessImage,
  },
  {
    path: appRoutes.leadgenSeamlessVideo,
  },
  {
    path: appRoutes.seamlessCarousel,
  },
  {
    path: appRoutes.nativeImage,
  },
  {
    path: appRoutes.adoptimizeClicks,
  },
  {
    path: appRoutes.deals,
  },
  {
    path: appRoutes.brandedContentADR,
  },
  {
    path: appRoutes.brandedContentNuNl,
  },
  {
    path: appRoutes.brandedContentVTP,
  },
  {
    path: appRoutes.brandedContentLMF,
  },
  {
    path: appRoutes.brandedContentHLN,
  },
  {
    path: appRoutes.brandedContentDeMorgen,
  },
  {
    path: appRoutes.brandedContent,
  },
  {
    path: appRoutes.listingAd,
  },
  {
    path: appRoutes.seamlessImageCrossDevice,
  },
  {
    path: appRoutes.seamlessImageMini,
  },
  {
    path: appRoutes.leadgenCreateSeamlessImage,
  },
  {
    path: appRoutes.leadgenCreateSeamlessVideo,
  },
  {
    path: appRoutes.brandedContentVideo,
  },
  {
    path: appRoutes.tweakersFeaturedProduct,
  },
  {
    path: appRoutes.tweakersFeaturedShop,
  },
  {
    path: appRoutes.tweakersAdvertorial,
  },
  // create routes

  {
    path: appRoutes.createSeamlessImage,
  },
  {
    path: appRoutes.createSeamlessVideo,
  },
  {
    path: appRoutes.createSeamlessCarousel,
  },
  {
    path: appRoutes.createAdoptimizeClicks,
  },
  {
    path: appRoutes.createBrandedContentADR,
  },
  {
    path: appRoutes.createBrandedContentNuNl,
  },
  {
    path: appRoutes.createBrandedContentVTP,
  },
  {
    path: appRoutes.createBrandedContentLMF,
  },
  {
    path: appRoutes.createBrandedContentHLN,
  },
  {
    path: appRoutes.createBrandedContentDeMorgen,
  },
  {
    path: appRoutes.createBrandedContent,
  },
  {
    path: appRoutes.createListingAd,
  },
  {
    path: appRoutes.createSeamlessImageCrossDevice,
  },
  {
    path: appRoutes.createSeamlessImageMini,
  },
  {
    path: appRoutes.createTweakersFeaturedProduct,
  },
  {
    path: appRoutes.createTweakersFeaturedShop,
  },
  {
    path: appRoutes.createTweakersAdvertorial,
  },

  {
    path: appRoutes.bulkUpload,
    type: 'BULK',
  },
];
