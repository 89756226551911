import React from 'react';

import { AppContextType, User } from './types';

export const AppContext = React.createContext<AppContextType>({
  user: {
    given_name: '',
    family_name: '',
  },
  token: '',
});

interface Props {
  children: React.ReactNode;
  context: AppContextType;
}

export const AppProvider: React.FC<Props> = ({ children, context }) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
