import { CampaignDataPayload, COUNTRY, queryKeys } from 'api';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CAMPAIGN_ITEM_ID_LS_KEY, COUNTRY_LS_KEY } from 'routes/appRoutes';

import { getCampaignData } from './queries';

export const useGetCampaignData = () => {
  const cache = useQueryClient();

  const cachedData = cache.getQueryData([queryKeys.campaignData]);

  const [search, _] = useSearchParams();
  const country =
    (search.get('source') as COUNTRY) ||
    localStorage.getItem(COUNTRY_LS_KEY) ||
    COUNTRY.NL;

  const campaignItemId =
    search.get('campaign_item_id') ||
    localStorage.getItem(CAMPAIGN_ITEM_ID_LS_KEY) ||
    'SEAMLESS_IMAGE';

  useEffect(() => {
    localStorage.setItem(COUNTRY_LS_KEY, country);
    localStorage.setItem(CAMPAIGN_ITEM_ID_LS_KEY, campaignItemId);
  }, []);

  const params: CampaignDataPayload = {
    country,
    campaignItemId,
  };

  return useQuery({
    queryKey: [queryKeys.campaignData],
    queryFn: () => getCampaignData(params),
    enabled: !cachedData,
  });
};
