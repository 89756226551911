import { AdFormat } from './globalTypes';

export interface Image {
  consumerSystemAdvertiserId: string;
  creationTimestamp: string | Date;
  fileUrl: string;
  height: number;
  id: number;
  imageSettings?: any;
  name: string;
  size: number;
  systemName?: string;
  width: number;
}

export enum AD_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
}

export interface MediaAsset {
  id: string;
  endDate: string;
  onNumber: string;
  startDate: string;
  state: AD_STATUS;
  thirdPartyState?: AD_STATUS.ACTIVE | AD_STATUS.INACTIVE;
  subtype: string;
  title: string;
  type: AdFormat;
  creatives: any[];
}
