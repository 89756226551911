import { queryKeys } from 'api';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { appRoutes } from 'routes/appRoutes';

interface RedirectProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  redirectRoute: string;
}
export const useRedirect = ({ setIsLoading, redirectRoute }: RedirectProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onCancel = () => {
    navigate(appRoutes.overview);
  };

  const onSave = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.campaignData] });
    navigate(appRoutes.overview);
  };

  const onDuplicate = (id: string) => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.campaignData] });

    if (redirectRoute) {
      navigate(redirectRoute.replace(':id', id));

      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  return { onCancel, onSave, onDuplicate };
};
