import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { convertSxToThemeType } from 'utils';

import { SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';

import { styles } from './styles';

export interface Props extends ButtonProps {
  children?: ReactNode;
  testId?: string;
  width?: number | string;
  to?: string;
  startIconName?: IconName;
  endIconName?: IconName;
  iconSx?: SxProps;
  labelId?: string;
  disabled?: boolean;
  onClick?(): void;
}

const ButtonComponent: React.FC<Props> = ({
  to,
  children,
  testId,
  type = 'button',
  width = 'fit-content',
  className,
  startIconName,
  endIconName,
  sx,
  iconSx,
  variant = 'contained',
  labelId,
  disabled = false,
  onClick,
  ...restProps
}) => {
  const buttonSx = convertSxToThemeType([
    styles.button,
    !!(endIconName || startIconName) && styles.buttonWithIcon,
    { width },
    sx as SxProps,
  ]);

  const btnIcon = useCallback(
    (iconName: IconName) => <Icon name={iconName} width={24} height={24} sx={iconSx} />,
    [iconSx, variant]
  );

  const getDefaultButton = useCallback(
    (props?: { to: string; component: React.ReactNode }) => (
      <Button
        variant={variant}
        className={className}
        type={type}
        sx={buttonSx}
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
        {...(startIconName && {
          startIcon: btnIcon(startIconName),
        })}
        {...(endIconName && {
          endIcon: btnIcon(endIconName),
        })}
        {...restProps}
        {...props}
      >
        {labelId && <FormattedMessage id={labelId} />}
        {children}
      </Button>
    ),
    [
      variant,
      className,
      type,
      buttonSx,
      testId,
      disabled,
      onClick,
      startIconName,
      btnIcon,
      endIconName,
      restProps,
      labelId,
      children,
    ]
  );

  if (to) {
    return getDefaultButton({ to, component: Link as unknown as React.ReactNode });
  }

  return getDefaultButton();
};

const GenButton = React.memo(ButtonComponent);

export { GenButton };
