import { AdFormat } from 'api/types';

export const appRoutes = {
  home: '/',
  overview: '/overview',
  seamlessImage: '/seamless-image/:id',
  createSeamlessImage: '/seamless-image/create',
  seamlessVideo: '/seamless-video/:id',
  createSeamlessVideo: '/seamless-video/create',
  leadgenSeamlessImage: '/leadgen-seamless-image/:id',
  leadgenCreateSeamlessImage: '/leadgen-seamless-image/create',
  leadgenSeamlessVideo: '/leadgen-seamless-video/:id',
  leadgenCreateSeamlessVideo: '/leadgen-seamless-video/create',
  seamlessCarousel: '/seamless-carousel/:id',
  createSeamlessCarousel: '/seamless-carousel/create',
  nativeImage: '/native-image/:id',
  createNativeImage: '/native-image/create',
  createAdoptimizeClicks: '/adoptimize-clicks/create',
  adoptimizeClicks: '/adoptimize-clicks/:id',
  createDeals: '/deals/create',
  deals: '/deals/:id',
  bulkUpload: '/bulk-upload',
  createListingAd: '/listing-ad/create',
  listingAd: '/listing-ad/:id',
  createSeamlessImageCrossDevice: '/seamless-image-cross-device/create',
  seamlessImageCrossDevice: '/seamless-image-cross-device/:id',
  createSeamlessImageMini: '/seamless-image-mini/create',
  seamlessImageMini: '/seamless-image-mini/:id',

  createBrandedContent: '/branded-content/create',
  brandedContent: '/branded-content/:id',
  createBrandedContentADR: '/branded-content-adr/create',
  brandedContentADR: '/branded-content-adr/:id',
  createBrandedContentNuNl: '/branded-content-nu-nl/create',
  brandedContentNuNl: '/branded-content-nu-nl/:id',
  createBrandedContentVTP: '/branded-content-vtp/create',
  brandedContentVTP: '/branded-content-vtp/:id',
  createBrandedContentLMF: '/branded-content-lmf/create',
  brandedContentLMF: '/branded-content-lmf/:id',
  createBrandedContentHLN: '/branded-content-hln/create',
  brandedContentHLN: '/branded-content-hln/:id',
  createBrandedContentDeMorgen: '/branded-content-de-morgen/create',
  brandedContentDeMorgen: '/branded-content-de-morgen/:id',
  createBrandedContentVideo: '/branded-content-video/create',
  brandedContentVideo: '/branded-content-video/:id',

  tweakersFeaturedProduct: '/tweakers-featured-product/:id',
  createTweakersFeaturedProduct: '/tweakers-featured-product/create',
  tweakersFeaturedShop: '/tweakers-featured-shop/:id',
  createTweakersFeaturedShop: '/tweakers-featured-shop/create',
  tweakersAdvertorial: '/tweakers-advertorial/:id',
  createTweakersAdvertorial: '/tweakers-advertorial/create',
};

export const getAppRouteFromCreativeFormat = (
  creativeFormat: AdFormat
): { createUrl: string; updateUrl: string } => {
  switch (creativeFormat) {
    case AdFormat.SEAMLESS_IMAGE:
    case AdFormat.SEAMLESS_IMAGE_L:
    case AdFormat.SEAMLESS_IMAGE_XL:
      return {
        createUrl: appRoutes.createSeamlessImage,
        updateUrl: appRoutes.seamlessImage,
      };

    case AdFormat.SEAMLESS_VIDEO:
    case AdFormat.SEAMLESS_VIDEO_L:
    case AdFormat.SEAMLESS_VIDEO_XL:
      return {
        createUrl: appRoutes.createSeamlessVideo,
        updateUrl: appRoutes.seamlessVideo,
      };
    case AdFormat.LEADGEN_SEAMLESS_IMAGE:
    case AdFormat.LEADGEN_SEAMLESS_IMAGE_L:
    case AdFormat.LEADGEN_SEAMLESS_IMAGE_XL:
      return {
        createUrl: appRoutes.leadgenCreateSeamlessImage,
        updateUrl: appRoutes.leadgenSeamlessImage,
      };

    case AdFormat.LEADGEN_SEAMLESS_VIDEO:
    case AdFormat.LEADGEN_SEAMLESS_VIDEO_L:
    case AdFormat.LEADGEN_SEAMLESS_VIDEO_XL:
      return {
        createUrl: appRoutes.leadgenCreateSeamlessVideo,
        updateUrl: appRoutes.leadgenSeamlessVideo,
      };
    case AdFormat.SEAMLESS_CAROUSEL:
      return {
        createUrl: appRoutes.createSeamlessCarousel,
        updateUrl: appRoutes.seamlessCarousel,
      };
    case AdFormat.NATIVE:
      return {
        createUrl: appRoutes.createNativeImage,
        updateUrl: appRoutes.nativeImage,
      };
    case AdFormat.ADOPTIMIZE_CLICKS:
      return {
        createUrl: appRoutes.createAdoptimizeClicks,
        updateUrl: appRoutes.adoptimizeClicks,
      };
    case AdFormat.DEALS:
      return {
        createUrl: appRoutes.createDeals,
        updateUrl: appRoutes.deals,
      };
    case AdFormat.BRANDED_CONTENT:
      return {
        createUrl: appRoutes.createBrandedContent,
        updateUrl: appRoutes.brandedContent,
      };

    case AdFormat.BRANDED_CONTENT_ADR:
      return {
        createUrl: appRoutes.createBrandedContentADR,
        updateUrl: appRoutes.brandedContentADR,
      };

    case AdFormat.BRANDED_CONTENT_NU_NL:
      return {
        createUrl: appRoutes.createBrandedContentNuNl,
        updateUrl: appRoutes.brandedContentNuNl,
      };

    case AdFormat.BRANDED_CONTENT_VTP:
      return {
        createUrl: appRoutes.createBrandedContentVTP,
        updateUrl: appRoutes.brandedContentVTP,
      };

    case AdFormat.BRANDED_CONTENT_LMF:
      return {
        createUrl: appRoutes.createBrandedContentLMF,
        updateUrl: appRoutes.brandedContentLMF,
      };

    case AdFormat.BRANDED_CONTENT_HLN:
      return {
        createUrl: appRoutes.createBrandedContentHLN,
        updateUrl: appRoutes.brandedContentHLN,
      };

    case AdFormat.BRANDED_CONTENT_DE_MORGEN:
      return {
        createUrl: appRoutes.createBrandedContentDeMorgen,
        updateUrl: appRoutes.brandedContentDeMorgen,
      };

    case AdFormat.LISTING_AD:
      return {
        createUrl: appRoutes.createListingAd,
        updateUrl: appRoutes.listingAd,
      };
    case AdFormat.SEAMLESS_IMAGE_CROSS_DEVICE:
      return {
        createUrl: appRoutes.createSeamlessImageCrossDevice,
        updateUrl: appRoutes.seamlessImageCrossDevice,
      };
    case AdFormat.SEAMLESS_IMAGE_MINI:
      return {
        createUrl: appRoutes.createSeamlessImageMini,
        updateUrl: appRoutes.seamlessImageMini,
      };

    case AdFormat.BRANDED_CONTENT_VIDEO:
      return {
        createUrl: appRoutes.createBrandedContentVideo,
        updateUrl: appRoutes.brandedContentVideo,
      };

    case AdFormat.TWEAKERS_FEATURED_PRODUCT:
      return {
        createUrl: appRoutes.createTweakersFeaturedProduct,
        updateUrl: appRoutes.tweakersFeaturedProduct,
      };
    case AdFormat.TWEAKERS_FEATURED_SHOP:
      return {
        createUrl: appRoutes.createTweakersFeaturedShop,
        updateUrl: appRoutes.tweakersFeaturedShop,
      };

    case AdFormat.TWEAKERS_ADVERTORIAL:
      return {
        createUrl: appRoutes.createTweakersAdvertorial,
        updateUrl: appRoutes.tweakersAdvertorial,
      };

    default:
      return {
        createUrl: appRoutes.createSeamlessImage,
        updateUrl: appRoutes.seamlessImage,
      };
  }
};

export const COUNTRY_LS_KEY = 'ls_design_country';
export const CAMPAIGN_ITEM_ID_LS_KEY = 'ls_design_campaign_item_id';
