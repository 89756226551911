import { Loader } from 'components';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { useAuth } from 'utils';

import { Box, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import { AppProvider } from 'components/app-provider';
import { QueryClientWrapper } from 'components/query-client-wrapper';
import { theme, themeColors } from 'theme';

import { Routes } from './routes/Routes';
console.log('production');
function App() {
  const { token, user, creativeId } = useAuth();

  useEffect(() => {
    const onBeforeUnloadCb = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('jwt_token');

      return true;
    };

    window.addEventListener('beforeunload', onBeforeUnloadCb);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadCb);
    };
  }, []);

  if (!token) {
    return <Loader fullWidth width={100} />;
  }

  return (
    <AppProvider context={{ user, token }}>
      <QueryClientWrapper>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <Box
                display="flex"
                sx={{ backgroundColor: themeColors.grey20 }}
                flexDirection="column"
                minHeight="100vh"
              >
                <SnackbarProvider
                  maxSnack={8}
                  autoHideDuration={1000000}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Routes creativeId={creativeId} />
                </SnackbarProvider>
              </Box>
            </CssBaseline>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientWrapper>
    </AppProvider>
  );
}

export default App;
