import React from 'react';

import { Grid } from '@mui/material';

import { styles } from 'components/menu/styles';

import { useMenuItems } from './useMenuItems';

const UserLogoDropdown: React.FC = () => {
  const { UserLogoElem } = useMenuItems();

  return (
    <Grid item sx={styles.grid}>
      {UserLogoElem}
      {/* out of scope for now */}
      {/* <Dropdown
        disableAutoFocusItem
        autoWidth={true}
        anchorElem={UserLogoElem}
        menuItems={menuItems}
        themeType={'expandable'}
      /> */}
    </Grid>
  );
};

export { UserLogoDropdown };
