import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { appRoutes } from 'routes';

import Box from '@mui/material/Box';
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/icon/Icon';

import { styles } from './styles';

export enum BreadcrumbStates {
  EDIT = 'edit',
  CREATE = 'create',
}

export const Breadcrumbs: React.FC<unknown> = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const breadcrumb = useMemo(() => {
    const breadcrumbState = id ? BreadcrumbStates.EDIT : BreadcrumbStates.CREATE;

    return pathname.includes(appRoutes.overview) ? undefined : breadcrumbState;
  }, [id, pathname]);

  return (
    <MuiBreadcrumbs
      separator={<Icon name="arrow-right" width="24px" height="24px" color="#A5ACB1" />}
      sx={styles.breadcrumb}
    >
      {breadcrumb && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ marginRight: 11 }} width="24px" height="24px" name={'ads-icon'} />
          <Typography sx={styles.lastItem} variant="body1">
            {breadcrumb === 'edit' && <FormattedMessage id="breadcrumbs.edit_creative" />}
            {breadcrumb === 'create' && (
              <FormattedMessage id="breadcrumbs.new_creative" />
            )}
          </Typography>
        </Box>
      )}
    </MuiBreadcrumbs>
  );
};
