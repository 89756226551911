import { COUNTRY } from 'api';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CAMPAIGN_ITEM_ID_LS_KEY, COUNTRY_LS_KEY } from 'routes';

import { useOneLoginAuth } from 'api/login';
import { User } from 'components/app-provider/types';

const threeHoursInMs = 10800000;

const formatHash = (hash: string) =>
  hash
    .split('&')
    .map((v) => v.split('='))
    .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

export const useAuth = (): { token: string | null; user?: User; creativeId: string } => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token') || null
  );

  const [user, setUser] = useState<User>();

  const { beginAuth } = useOneLoginAuth();
  const [search, _] = useSearchParams();
  const [creativeId, setCreativeId] = useState(search.get('creative_id') || '');
  const country =
    (search.get('source') as COUNTRY) ||
    localStorage.getItem(COUNTRY_LS_KEY) ||
    COUNTRY.NL;

  const jwt_token = localStorage.getItem('jwt_token') || '';

  const campaignItemId =
    search.get('campaign_item_id') ||
    localStorage.getItem(CAMPAIGN_ITEM_ID_LS_KEY) ||
    'SEAMLESS_IMAGE';

  useEffect(() => {
    if (!hash && !token) {
      localStorage.setItem(COUNTRY_LS_KEY, country);
      localStorage.setItem(CAMPAIGN_ITEM_ID_LS_KEY, campaignItemId);

      beginAuth();
    }
    if (hash && !token) {
      const formattedHash = formatHash(hash.substring(1)) as {
        access_token: string;
        expires_in: number;
        id_token: string;
        token_type: string;
        state: string;
      };

      const expiresInMilliseconds = formattedHash.expires_in * 1000 || threeHoursInMs;

      setTimeout(() => {
        beginAuth();
      }, expiresInMilliseconds);

      localStorage.setItem('jwt_token', formattedHash.id_token);
      const { customRedirectUri, search } = JSON.parse(
        decodeURIComponent(formattedHash.state)
      );
      const parsedSearch = search
        ? new URLSearchParams(search.substring(search.indexOf('?')))
        : null;
      const searchCreativeId = parsedSearch && parsedSearch.get('creative_id');

      if (searchCreativeId) {
        setCreativeId(searchCreativeId);
      }
      const accessToken = formattedHash?.access_token;
      setToken(accessToken);
      localStorage.setItem('token', accessToken);

      if (
        customRedirectUri?.includes('seamless') ||
        customRedirectUri?.includes('native') ||
        customRedirectUri?.includes('deals') ||
        customRedirectUri?.includes('adoptimize') ||
        customRedirectUri?.includes('listing') ||
        customRedirectUri?.includes('tweakers') ||
        customRedirectUri?.includes('branded-content')
      ) {
        navigate(decodeURIComponent(customRedirectUri), { replace: true });
      }
    }

    if (jwt_token) {
      setUser(jwtDecode(jwt_token));
    }
  }, [hash, token]);

  return {
    token,
    user,
    creativeId,
  };
};
