import { AppLayout, Loader } from 'components';
import { BulkUploadView } from 'dpg-design-fe-library';
import React, { useEffect } from 'react';
import { Routes as BaseRoutes, Navigate, Route, useNavigate } from 'react-router-dom';

import { OffsetContainer } from 'components/offset-container';

import { useGetCampaignData } from './api/hooks';
import { appRoutes, getAppRouteFromCreativeFormat } from './appRoutes';
import { viewRoutes } from './constants';

const Overview = React.lazy(() => import('features/Overview'));
const ViewComponent = React.lazy(() => import('features/view-component/ViewComponent'));

export const Routes = ({ creativeId }: { creativeId: string }) => {
  const { isSuccess, data } = useGetCampaignData();

  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.creativeFormat) {
      const { updateUrl } = getAppRouteFromCreativeFormat(data.creativeFormat);

      if (creativeId) {
        navigate(updateUrl.replace(':id', creativeId));
        return;
      }
    }
  }, [isSuccess]);

  if (!isSuccess) {
    return <Loader fullWidth width={100} />;
  }

  return (
    <BaseRoutes>
      <Route element={<AppLayout />}>
        <Route
          path={appRoutes.overview}
          element={
            <OffsetContainer>
              <Overview />
            </OffsetContainer>
          }
        />

        {viewRoutes.map((view) => (
          <Route
            key={view.path}
            path={view.path}
            element={<ViewComponent type={view.type} />}
          />
        ))}

        <Route key={'*'} path="*" element={<Navigate to={appRoutes.overview} />} />
      </Route>
    </BaseRoutes>
  );
};
