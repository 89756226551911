import { useContext, useMemo } from 'react';

import { Typography } from '@mui/material';

import { AppContext } from 'components/app-provider';

import { styles } from './styles';

export const useMenuItems = () => {
  const { user } = useContext(AppContext);
  const { userAlias } = useMemo(() => {
    const firstName = user?.given_name;
    const lastName = user?.family_name;
    if (!firstName || !lastName) {
      return {
        userAlias: '',
        userName: '',
      };
    }
    return {
      userAlias: firstName.charAt(0) + lastName.charAt(0),
      userName: `${firstName} ${lastName}`,
    };
  }, [user?.family_name, user?.given_name]);

  const UserLogoElem = useMemo(
    () => (
      <Typography variant="h5" sx={styles.title}>
        {userAlias}
      </Typography>
    ),
    [userAlias]
  );

  return { UserLogoElem };
};
