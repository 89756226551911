import { theme } from 'theme';
import { themeColors } from 'theme/themeColors';

export const styles = {
  root: {
    '& a, & .link': {
      display: 'inline-block',
      padding: '15px 20px',
      margin: '20px 0',
      color: theme.palette.text.primary,
      textDecoration: 'none',
      transition: 'color linear 200ms',
    },
    '& a.active': {
      pointerEvents: 'none',
    },
    '& a.active, & a:active, & a:hover, & .link:hover': {
      color: theme.palette.error.contrastText,
      cursor: 'pointer',
    },
    '& a.settings svg': {
      width: 24,
      heigth: 24,
      minWidth: 20,
      minHeight: 20,
    },
    '& a.active.nav-reports': {
      '& .nav-reports-path, & .nav-reports-path:hover': {
        fill: theme.palette.error.contrastText,
      },
    },
  },
  breadcrumbs: {
    marginLeft: '50px',
    maxWidth: '100%',
  },
  breadcrumbsContainer: {
    maxWidth: '100%',
    paddingRight: '40px',
  },
  title: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: themeColors.grey20,
    lineHeight: '30px',
    padding: 0,
    textAlign: 'center',
    color: themeColors.grey120,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'default',
  },
  grid: {
    padding: '33px 20px 35px',
  },
  customMenuItem: {
    color: themeColors.purplePrimary,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Proxima Nova Bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline !important',
      color: themeColors.purpleHover,
      '& svg': {
        fill: `${themeColors.purpleHover} !important`,
        '& g': {
          fill: `${themeColors.purpleHover} !important`,
        },
      },
    },
  },
  customHover: {
    '&:hover, &:focus': {
      background: 'unset',
      cursor: 'default',
    },
  },
  headWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    color: themeColors.black,
    cursor: 'default',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    maxWidth: '234px',
  },
  emailWrap: {
    margin: '4px 0',
    fontFamily: 'Proxima Nova Regular',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
  },
  roleWrap: {
    fontSize: '14px',
    color: themeColors.grey120,
  },
};
